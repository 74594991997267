<template>
  <moe-page title="查看店铺折扣">
    <moe-card class="mb-20" :autoHeight="true">
      <template slot="header">
        <moe-steps :active="active" :stepsList="[{ title: '基本信息' }, { title: '优惠门槛和内容' }, { title: '商品选择' }, { title: '完成设置' }]"></moe-steps>
      </template>

      <moe-form :showBack="false">
        <moe-describe-list title="" :col="2">
          <moe-describe-item label="活动名称"> {{ shopDiscountData.name }} </moe-describe-item>
          <moe-describe-item label="活动时间" class="wsn"> {{ shopDiscountData.startTime }} 至 {{ shopDiscountData.endTime }} </moe-describe-item>
          <moe-describe-item label="优惠类型"> {{ $moe_format.getDiscountLevel(shopDiscountData.level) }} </moe-describe-item>
          <moe-describe-item label="创建时间" class="wsn"> {{ shopDiscountData.createTime }} </moe-describe-item>
        </moe-describe-list>

        <div class="fwb mb-10">优惠条件</div>
        <moe-describe-list title="" :col="1">
          <moe-describe-item label="优惠条件">{{ $moe_format.getShopDiscountMethod(shopDiscountData.method) }}</moe-describe-item>
        </moe-describe-list>

        <div v-for="(item, index) in shopDiscountData.contents" :key="index">
          <div class="fwb">{{ `优惠门槛及内容 - 阶梯${index + 1}` }}</div>
          <moe-describe-list title="" :col="1">
            <template v-if="shopDiscountData.method === 'DISCOUNT'">
              <moe-describe-item>满 {{ item.condition }} 件</moe-describe-item>
              <moe-describe-item>打 {{ item.discount }} 折</moe-describe-item>
            </template>
            <template v-else-if="shopDiscountData.method === 'REDUCE'">
              <moe-describe-item>满 {{ item.condition }} 元</moe-describe-item>
              <moe-describe-item>减 {{ item.discount }} 元</moe-describe-item>
            </template>
          </moe-describe-list>
        </div>

        <template v-if="shopDiscountData.level === 'ALL'">
          <div class="fwb mb-20">指定活动商品</div>
          <el-form-item label-width="50px">
            <div>指定商品：全部商品(已参加其他活动的商品除外)</div>
            <div>1.本次活动的商品范国是，本活动时段内，全店商品中未参加其它活动的所有商品</div>
            <div>2.活动生效时段内新发布的商品，也会参加到本活动中</div>
          </el-form-item>
        </template>
      </moe-form>

      <template v-if="shopDiscountData.level === 'PART'">
        <div style="height: 700px;min-height: 700px;" class="df fdc">
          <moe-table
            :numberShow="false"
            :data="shopDiscountData.configList"
            :mode="false"
            :showPage="false"
            :params="{ pageNum: 1 }"
            emptyText="请添加活动商品">
            <el-table-column label="商品信息" min-width="200">
              <template slot-scope="{ row }">
                <div class="df aic">
                  <moe-image :src="row.coverUrl" width="80px" height="80px" />
                  <div style="text-align:left" class="ml-10">
                    <p class="fwb">{{ row.name }}</p>
                    <p class="font-12 color-info">商品编号: {{ row.goodsId }}</p>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="销售价" min-width="80">
              <template slot-scope="{ row }">
                {{ `￥ ${row.minPrice}` }}{{ $moe_math.mathGreaterThan(row.maxPrice, row.minPrice) ? ` - ¥ ${row.maxPrice}` : '' }}
              </template>
            </el-table-column>
            <el-table-column prop="stock" label="库存" min-width="80" />
          </moe-table>
        </div>
      </template>

      <moe-form :showBack="false">
        <template slot="tool">
          <el-button type="primary" icon="el-icon-back" @click="$moe_coordinator.navigateBack()">返回列表</el-button>
        </template>
      </moe-form>
    </moe-card>
  </moe-page>
</template>

<script>
export default {
  name: 'ActivityManageShopDiscountDetail',
  computed: {
    active() {
      if (!this.shopDiscountData.id) {
        return 0
      }
      if (!this.shopDiscountData.contents.length) {
        return 1
      }
      if (!this.shopDiscountData.configList.length && this.shopDiscountData.level === 'PART') {
        return 2
      }
      return 4
    }
  },
  data() {
    return {
      shopDiscountData: {},
    }
  },
  methods: {
    /** 获取折扣活动详情 */
    getDiscountDetail() {
      if (this.$route.query.id) {
        this.$moe_api.SHOP_DISCOUNT.getDiscountDetail({ id: this.$route.query.id }).then((data) => {
          if (data.code === 200) {
            let r = data.result;
            this.shopDiscountData = {
              id: r.id,
              name: r.name,
              method: r.method,
              level: r.level,
              createTime: r.createTime,
              startTime: r.startTime,
              endTime: r.endTime,
              contents: [],
              configList: [],
            };

            if (r.contents && r.contents.length) {
              this.shopDiscountData.contents = r.contents;
            }

            if (r.configList.length) {
              this.getQueryShopGoodsByIds(r.configList);
            }
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      }
    },
    /** 根据商品ID获取商品列表 */
    getQueryShopGoodsByIds(configList) {
      this.$moe_api.GOODS_API.getQueryShopGoodsByIds({ ids: configList.map(({ goodsId }) => goodsId).join(',') }).then((data) => {
        if (data.code === 200) {
          this.shopDiscountData.configList = configList.map((item) => {
            let findGoodsItem = data.result.find(({ id }) => item.goodsId === id);
            return {
              ...findGoodsItem,
              goodsId: item.goodsId,
              id: item.id,
            }
          })
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
  },
  mounted() {
    this.getDiscountDetail();
  }
}
</script>

<style lang="scss"></style>